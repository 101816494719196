@media only screen and (max-width: 600px) {



    .mobile-hidden,
    .filter.mobile-hidden,
    .toolbar .btn .text {
        display: none;
    }

    .toolbar .btn {
        min-width: 20px;
        min-height: 40px;
        margin-right: 10px;
    }

    .tile-container {
        justify-content: center;
    }

    .imageTileCircle {
        width: 120px;
        height: 110px;
    }

    .imageTileCircle .image {
        width: 50px;
        height: 50px;
    }

    .table.products .imageTileCircle {
        width: 50px;
        height: 50px;
    }

    .control.checklist .contact-pop-over {
        display: none;
    }

    .structure-display img {
        width: 90%;
    }

}