.append-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

    .append-button .payload {
        width: 100%;
    }


    .append-button .btn {
        margin: unset;
        padding: unset;
        min-width: 50px;
        border-radius: unset;
    }


.default-spacer {
    display: block;
    width: 100%;
    margin: 40px 0px 00px 0px;
}