/* THIS SHOULD BE MOVED TO STYLE GUIDE APP */

label {
    color: var(--color-blue);
    font-weight: bold;
    display: inline-block;
    margin: 20px 0px 0px 5px;
}

.default-space {
    display: block;
    width: 100%;
    height: 50px;
}

.close-modal {
}

.disabled-close-modal {
    cursor: not-allowed;
}

.codeblock {
    display: block;
    font-family: Courier New;
    word-break: break-all;
    padding: 0px 20px 0px 0px;
}

.card .container .error-message,
.error-message {
    color: #BB1128;
}

.modal .header {
    display: flex;
    justify-content: space-between;
    box-shadow: unset;
    margin: 10px;
}

    .modal .header .icon {
        padding: 15px 10px 10px 10px;
    }

.modal, .confirmation {
    max-width: 75%;
}

    .modal .content {
        margin: 10px;
    }

        .modal .content .profile label {
            padding: 0px;
            margin: 0px;
        }

        .modal .content .profile .row {
            padding: 10px 0px 10px 10px;
            border-bottom: 1px solid #ccc;
        }

    .modal .footer-buttons {
        background: #eee;
        color: #00B3E8;
        width: 100%;
        display: inline-block;
        text-align: center;
        padding: 20px 0px 20px 0px;
        -webkit-border-bottom-right-radius: 20px;
        -webkit-border-bottom-left-radius: 20px;
        -moz-border-radius-bottomright: 20px;
        -moz-border-radius-bottomleft: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        cursor: pointer;
    }

        .modal .footer-buttons .left {
            float: left;
            width: 50%;
            color: #F47745
        }

        .modal .footer-buttons .right {
            float:right;
            width:50%
            
        }
.modal-component .header-modal {
    float: right;
    /*    margin:-5px;*/
}

.react-datepicker__input-container input {
    padding: 10px;
    border-radius:4px;


}
    .react-datepicker__input-container .input:valid {
        border: 1px solid #619B2D;
    }

.card .title {
    width: calc(100% - 30px);
}

.form-radio {
    text-align: left;
}

textarea {
    resize: none;
}

.selectable {
    cursor: pointer;
}

.overflow-hidden {
    overflow: hidden;
}

.flex {
    display: flex;
    justify-content: flex-start;
}

.justify-content-center,
.flex-center {
    display: flex;
    justify-content: center;
}

.flex > * {
    margin: 0px 4px 0px 0px;
}


.btn:disabled {
    opacity: 0.25;
}

.btn .glyphicon {
    padding: 0px 5px 0px 0px;
}

.col {    
    padding: 4px;
    word-break: break-word;
    vertical-align: top;
    float: left;
}

.col-1 {
    width: calc((100% / 12) * 1 - 8px) ;
}

.col-2 {
    width: calc((100% / 12) * 2 - 8px);
}

.col-3 {
    width: calc((100% / 12) * 3 - 8px);
}

.col-4 {
    width: calc((100% / 12) * 4 - 8px);
}

.col-5 {
    width: calc((100% / 12) * 5 - 8px);
}

.col-6 {
    width: calc((100% / 12) * 5 - 8px);
}

.col-7 {
    width: calc((100% / 12) * 7 - 8px);
}

.col-8 {
    width: calc((100% / 12) * 8 - 8px);
}

.col-9 {
    width: calc((100% / 12) * 9 - 8px);
}

.col-10 {
    width: calc((100% / 12) * 10 - 8px);
}

.col-11 {
    width: calc((100% / 12) * 11 - 8px);
}

.col-12 {
    width: calc((100% / 12) * 12 - 8px);
}

.menu .menu-separator {
    display: inline-block;
    border-bottom: 1px solid #555;
    margin: 5px 0px 5px 0px;
    width: 100%;
}


.menu .menu-item {
    cursor: pointer;
    padding: 5px 0px 5px 0px;
}


.col .btn {
    padding: 0px;
    margin: 0px 10px 0px 0px;
    min-width: 50px;
    min-height: 30px;
}


.card .container label {
    color: var(--color-blue);
    font-weight: bold;
    display: block;
    margin: 20px 0px 10px 0px;
}

.card .container p {
    font-size: 16px;
}

.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px 20px;
    width: 100%;
}

    .flex-container .flex-item {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #555;
        text-align: center;
        width: 140px;
        height: 50px;
    }

        .flex-container .flex-item .synonym {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 50px;
        }

            .flex-container .flex-item .synonym .text {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 8;
                width: 20px;
                height: 50px;
                padding: unset;
            }

            .flex-container .flex-item .synonym .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
                min-width: unset;
                min-height: unset;
                height: 50px;
                margin: 0px;
                padding: 0px;
                border: unset;
                -webkit-border-radius: unset;
                -moz-border-radius: unset;
                border-radius: unset;
            }

.toolbar .btn,
.toolbar-control .btn {
    margin-right: 20px;
}

.control.image {
    width: 185px;
    display: inline-block;
}



.optionsContainer .listItem .radio {
    position: fixed;
    opacity: 0;
    pointer-events: none;
}

    .optionsContainer .listItem .radio:checked + label {
        color: #00B3E8;
        text-decoration: underline;
    }

.optionsContainer .listItem:hover .radio + label {
    color: #fff;
}


.synonyms-list-data-control .append-button .payload {
    width: 25%;
}

.paging {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

    .paging .button-section {
        width: 200px;
    }

    .paging .filter {
        display: flex;
        width: 400px;
    }

.btn-sm {
    padding: unset;
    margin: unset;
    min-width: 50px;
    margin: 0px 10px 0px 10px;
}


    .btn-sm.no-marging-right {
        margin-right: 0px;
    }

    .btn-sm.no-marging-left {
        margin-left: 0px;
    }


.table {
    width: 100%;
    display: block;
    background: #fff;
}

    .table .table-header {
        width: 100%;
        display: block;
        height: unset;
        background: #000;
        color: #fff;
        font-weight: bold;
        margin: 30px 0px 10px 0px;
        padding: 5px 0px 5px 0px;
    }

        .table .table-header .row {
            height: 40px;
            border: none;
            /*padding: 0px 5px 0px 5px;*/
        }

            .table .table-header .row .col:first-child {
                padding-left: 10px;
            }

            .table .table-header .row .col:last-child {
                padding-right: 10px;
            }


    .table .body {
        width: 100%;
        display: block;
    }

    .table .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px 10px 0px;
        border-bottom: 1px solid #eee;
    }

    .table.products .imageTileCircle {
        height: 100px;
    }

    .table.products .image {
    }



    .table.products .name {
        width: 200px;
    }

    .table.products .user {
        width: 200px;
    }

    .table.products .date {
        width: 200px;
    }

    .table.products .control {
        width: 100px;
    }



.tile-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}

.generic-display-component .row,
.generic-input-component .row {
    margin: 0px 0px 30px 0px;
}


.round-indicator {
    display: block;
    width: 20px;
    height: 20px;
    background: #eee;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

    .round-indicator.success {
        background: var(--color-green);
    }

    .round-indicator.error {
        background: var(--color-error);
    }

    .round-indicator .popover {
        position: absolute;
        margin: 20px;
        border: 1px solid #eee;
        background: #f5f5f5;
        padding: 5px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        filter: drop-shadow(1px 1px 2px #000000);
    }


.card .container h3 {
    margin: 20px 0px 10px 0px;
}


.modal .title {
    margin: 0px;
}

.modal .btn {
    margin: 0px;
}

.alerts-component {
    position: fixed;
    display: inline-block;
    right: 15px;
    top: 100px;
    width: 300px;
    z-index: 99999;
}

    .alerts-component .alert {
        filter: drop-shadow(5px 5px 10px #000000);
    }

        .alerts-component .alert .alert-title {
            background: #000;
            color: #fff;
            padding: 5px;
        }

.spinner-circle {
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.similar-items img {
    max-width: 50px;
    max-height: 50px;
}

.modal .modalContent {
    text-align: unset;
    max-height: 75vh;
    overflow: auto;
}



.form-check {
    display: inline-block;
    width: 100%;
    margin: 5px 0px 5px 0px;
}

    .form-check .form-check-input {
        cursor: pointer;
        display: block;
        float: left;
        margin: 5px 0px 0px 0px
    }

    .card .container .form-check .form-check-label,
    .form-check .form-check-label {
        cursor: pointer;
        display: block;
        float: left;
        margin: 0px 0px 0px 10px;
    }


.control.checklist {
    position: relative;
    min-height: 100px;
}

    .control.checklist .contact-pop-over {
        position: absolute;
        right: 16px;
        margin: 0px;
    }

        .control.checklist .contact-pop-over .pop-over {
            width: 150px;
        }

            .control.checklist .contact-pop-over .pop-over .triangle {
                margin: 0px 0px 0px 52px;
            }


        .control.checklist .contact-pop-over .btn-primary {
            padding: 0px 5px 0px 5px;
            width: unset;
            min-width: 130px;
        }



    .control.checklist .scroll-area {
        display: inline-block;
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
    }


    .control.checklist .filter {
        padding: 15px 0px 20px 0px;
        display: flex;
    }

        .control.checklist .filter .textbox {
            height: 35px;
        }

        .control.checklist .filter .btn {
            height: 30px;
            padding: 0px;
            min-width: 40px;
            margin: unset;
        }


.dropdown-item {
    display: inline-block;
    width: 100%;
    padding: 5px 0px 5px 0px;
    cursor: pointer;
}

    .dropdown-item .glyphicon,
    .dropdown-item .empty {
        width: 20px;
        display: inline-block;
    }

.dropdown-divider {
    width: 100%;
    display: block;
    margin: 10px 0px 10px 0px;
    border-bottom: 1px solid #555;
}


.products-by-structureSpecList-control .lookup-data-source-list-control .item-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

    .products-by-structureSpecList-control .lookup-data-source-list-control .item-container .row {
        width: unset;
        display: unset;
    }


.lookup-data-source-list-control .row,
.discontinue-References-control .row,
.structure-spec-rules-list .row {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #eee;
}



.productGroupRow .card.expandable {
    color: #5A5B5D;
}

    .productGroupRow .card.expandable .title .textContainer .titleText {
        font-size: unset;
        color: #5A5B5D;
    }


.productGroupCardTitle {
    display: inline-block;
    width: 95%;
}

    .productGroupCardTitle .titleRow {
        display: flex;
        justify-content: space-between;
    }


        .productGroupCardTitle .titleRow .caption {
            display: inline-block;
        }

        .productGroupCardTitle .titleRow .controls {
            display: flex;
            width: 150px;
            align-items: center;
            justify-content: flex-end;
        }


    .productGroupCardTitle .imagePreview,
    .productGroupCardTitle .properties {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;
    }


        .productGroupCardTitle .properties .item {
            font-size: 11px;
            display: inline-block;
            padding: 5px;
            width: 140px;
            min-height: 45px;
            border: 1px solid #eee;
        }


            .productGroupCardTitle .properties .item .caption {
                color: var(--color-blue)
            }


.productGroupRow .panelContainer {
    display: flex;
    flex-wrap: nowrap;
}

    .productGroupRow .panelContainer .item {
        width: 50%;
    }


.tinyImageLineItem .imageTileCircle {
    width: 70px;
    height: 90px;
}

    .tinyImageLineItem .imageTileCircle .image {
        width: 50px;
        height: 50px;
    }

    .tinyImageLineItem .imageTileCircle .caption {
        font-size: 11px;
        height: 30px;
    }


.product-view-component .relationship-items-display-data-control .row {
    border: unset;
}

.table.group-attributes .row {
    align-items: unset;
}



.product-group-editor-component .structure-specs .form-check {
    display: flex;
}



.rule-editor .card .title .textContainer .titleText .titleRow {
    display: flex;
    justify-content: space-between;
}


    .rule-editor .card .title .textContainer .titleText .titleRow .btn {
        font-size: 12px;
        padding: 0px;
        margin: 0px;
        min-width: 30px;
    }



.rulePopup .table th, table td {
    padding: 30px 0px 30px 0px;
    border-bottom: 1px solid #eee;
}

.rulePopup {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
}

    .rulePopup .inner-content {
        padding: 10px 0px 10px 0px;
    }

.rule-editor .card .container p {
    line-height: unset;
}

.round-md {
    text-align: center;
    width: 41px;
    height: 41px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.connector {
    width: 3px;
    height: 40px;
    background: #eee;
}

.rule-editor .card .card {
    margin-top: 0px;
}

.btn-round {
    width: 41px;
    height: 41px;
    min-width: unset;
    min-height: unset;
    padding: 0px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    margin: 0px;
}

.translation-iframe {
    height: 70vh;
    width: 100%;
}


.modal.translations {
    max-width: unset;
    width: 90%;
}

.photo-select-component .pageable-container {
    padding: 20px;
}


    .photo-select-component .pageable-container .photo-container {
        display: flex;
        flex-wrap: wrap;
        max-height: 50vh;
        overflow-y: auto;
    }



.notificationPopup {
    display: inline-block;
    position: fixed;
    background: #fff;
    width: 380px;
    right: 20px;
    bottom: 20px;
    filter: drop-shadow(3px 3px 3px #000000);
}


    .notificationPopup .title {
        background: var(--color-green);
        margin: unset;
        color: #fff;
        font-weight: bold;
    }

    .notificationPopup .btn {
        margin: unset;
    }

    .notificationPopup .content {
        padding: 10px;
    }



.policy-confirmation {
    padding: 10px;
}

.modal-component .title {
    padding: 15px 0px 15px 10px;
}

.policy-confirmation ul {
    margin: 0px;
    padding: 0px;
}

    .policy-confirmation ul li {
        margin: 0px;
        padding: 5px 0px 5px 0px;
        list-style: none;
    }

.policy-confirmation table th,
.policy-confirmation table td {
    text-align: unset;
    vertical-align: top;
}


.releaseInfo .row {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #eee;
}

.certSpecsList {
    margin: 0px 0px 30px 0px;
}


.row-padding {
    padding: 10px 0px 10px 0px;
}

.border-bottom {
    border-bottom: 1px solid #ccc;
}


.structure-display .filtered-product-list .round-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin: 0px 10px 0px 0px;
}