.tile {
    color: #fff;
    transition: all 0.4s ease-in-out 0s;
    width: 130px;
    height: 130px;
    padding: 0px;
    border-width: 3px;
    border-style: solid;
    border-radius: .38em;
    font-family: Montserrat;
    font-size: 0.88em;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background: var(--color-orange);
    border-color: var(--color-orange);
    box-shadow: unset;
    margin: 10px;
    position: relative;
}

    .tile .tile-balloon-dummy {
        position: absolute;
        margin: -20px 115px;
        width: 30px;
        height: 30px;        
    }

        .tile .tile-balloon-dummy .balloon {
            background: #000;
            color: #fff;
            width: 30px;
            height: 30px;
            text-align: center;
            vertical-align: middle;            
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            border-radius: 15px;
            margin: auto;
            vertical-align: middle;
        }

            .tile .tile-balloon-dummy .balloon .balloon-text {
                padding: 4px 0px 0px 0px;
            }


            .tile .tile-button {
            }

        .tile .tile-button .icon {
            float: left;
            display: block;
            width: 100%;
            text-align: left;
            margin: -14px 10px 0px 0px;
        }

        .tile .tile-button .text {
            display: block;
            width: 130px;            
            padding-top: 24px;
        }
