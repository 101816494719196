/* Toggle Switch */
.toggle-switch {
    display: inline-block;
    width: 100%;
    font-size: 16px;
}

    .toggle-switch .off {
    }

    .toggle-switch .on {
    }

    .toggle-switch .container {
        min-width: unset;
        width: 50px;
        height: 30px;
        float: left;
        padding: 0px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        border: 1px solid #333;
        cursor: pointer;
    }

    .toggle-switch .off .container {
        background: #eee;
    }

    .toggle-switch .on .container {
        background: #7cbd42;
    }


    .toggle-switch .switch {
        width: 20px;
        height: 20px;
        -webkit-border-radius: 13px;
        -moz-border-radius: 13px;
        border-radius: 13px;
        border: 4px solid #333;
        margin: 1px 1px 0px 1px;
    }

    .toggle-switch .off .switch {
        float: left;
        background: #555;
    }

    .toggle-switch .on .switch {
        float: right;
        background: #eee;
    }

    .toggle-switch .turned-on-label {
        height: 30px;
        float: left;
        padding: 2px 5px 0px 5px;
    }
