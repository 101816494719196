.generic-list-component.certificates .body .row {
    align-items: flex-start;
}

.card .container .table.dimension label {
    margin: 0px;
}

.table.dimension .row {
    padding: unset;
}